import Vue from 'vue/dist/vue.js';
import AOS from 'aos';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import MoveTo from 'moveto';
import About from './components/about';
import CoverVideo from './components/cover-video';
import ImagesGallery from './components/images-gallery';
import ContactForm from './components/contact-form';
import AboutContactForm from './components/about-contact-form';
import NewsletterForm from './components/newsletter-form';

gsap.registerPlugin(ScrollTrigger);

// Animations on scroll
AOS.init({
  duration: 600,
  disable: 'mobile',
  once: true,
});

// eslint-disable-next-line
const app = new Vue({
  el: '#app',
  components: {
    Swiper,
    SwiperSlide,
    About,
    CoverVideo,
    ImagesGallery,
    ContactForm,
    AboutContactForm,
    NewsletterForm,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      moveToIsDisabled: false,
      isUsingMoveTo: false,
      moveTo: null,
      scrollTop: window.pageYOffset,
      scrollDirection: 'none',
    };
  },
  mounted() {
    if (this.$refs.about) {
      this.moveToIsDisabled = true;
    }
    if (!this.moveToIsDisabled) {
      this.initMoveTo();
    }
    this.bindEventListeners();
  },
  methods: {
    toggleMobileNav() {
      // Toggles mobile nav display (with css transitions)
      if (this.$refs.topNav.classList.contains('is-opened')) {
        this.$refs.topNavHamburger.classList.remove('is-active');
        this.$refs.topNav.classList.add('is-closing');
        setTimeout(() => {
          this.$refs.topNav.classList.remove('is-opened');
          this.$refs.topNav.classList.remove('is-closing');
        }, 500);
      } else {
        this.$refs.topNavHamburger.classList.add('is-active');
        this.$refs.topNav.classList.add('is-opened');
      }
    },
    initMoveTo() {
      this.moveTo = new MoveTo({
        callback: () => {
          this.isUsingMoveTo = false;

          if (
            this.$refs.coverVideo &&
            this.$refs.coverVideo.$refs.video.paused
          ) {
            this.$refs.coverVideo.$refs.video.play();
          }
        },
      });
    },
    bindEventListeners() {
      if (!this.moveToIsDisabled) {
        setTimeout(() => {
          window.addEventListener('scroll', () => {
            const direction =
              window.pageYOffset > this.scrollTop ? 'down' : 'up';

            if (!this.isUsingMoveTo) {
              if (direction === 'down' && this.scrollTop < 100) {
                this.isUsingMoveTo = true;
                if (this.$refs.coverVideo) {
                  this.$refs.coverVideo.$refs.video.pause();
                }
                this.moveTo.move(document.querySelector('.js-page-content'));
              } else if (
                direction === 'up' &&
                this.scrollTop < window.innerHeight - 25
              ) {
                this.isUsingMoveTo = true;
                this.moveTo.move(document.querySelector('#app'));
              }
            }

            this.scrollTop = window.pageYOffset;
          });
        }, 500);
      }
    },
  },
});
