import isEmail from '../utils/isEmail';

export default {
  name: 'newsletter-form',
  data() {
    return {
      email: '',
      formIsSending: false,
      formSent: false,
      validationStarted: false,
    };
  },
  computed: {
    hasValidEmail() {
      return isEmail(this.email);
    },
  },
  methods: {
    checkForm(e) {
      this.formIsSending = true;

      if (!this.validationStarted) this.validationStarted = true;

      if (!this.hasValidEmail) return (this.formIsSending = false);

      fetch('/newsletter', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({ email: this.email }),
      })
        .then((response) => {
          this.formSent = true;
          this.formIsSending = false;
        })
        .catch((error) => {
          this.backendError = error;
          this.formIsSending = false;
        });
    },
  },
};
