import coverVid from './../_vendors/covervid/covervid';
import { sample } from 'lodash';
import isMobile from '../utils/isMobile';

export default {
  name: 'cover-video',
  props: {
    videoFiles: {
      type: Array,
      required: true,
    },
    videoInit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      videosToPlay: [],
      currentVideo: '',
      videoWidth: 0,
      videoHeight: 0,
    };
  },
  created() {
    this.currentVideo = this.videoInit;
  },
  mounted() {
    this.currentVideo = this.videoInit;
    this.videosToPlay = this.videoFiles.filter((video) => {
      return video !== this.currentVideo;
    });

    const initCoverVid = (e) => {
      this.$refs.video.removeEventListener('loadedmetadata', initCoverVid);
      this.videoWidth = e.target.videoWidth;
      this.videoHeight = e.target.videoHeight;

      coverVid(this.$refs.video, 1920, 1080, {
        isMobile: this.isMobile,
        srcWidth: this.videoWidth,
        srcHeight: this.videoHeight,
      });

      this.randomizeVideo();

      // if cover video is not in viewport, pause video
      if (window.scrollY > window.innerHeight) this.$refs.video.pause();
    };

    this.$refs.video.addEventListener('loadedmetadata', initCoverVid);
  },
  computed: {
    videoSource() {
      const folder = "https://static.creativeroom.be/" + (this.isMobile ? '/videos/mobile/' : '/videos/');

      return folder + this.currentVideo;
    },
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    randomizeVideo() {
      this.$refs.video.addEventListener('ended', (e) => {
        const nextVideo = sample(this.videosToPlay);
        this.currentVideo = nextVideo;

        this.videosToPlay = this.videosToPlay.filter((video) => {
          return video !== this.currentVideo;
        });

        // if there's no video left to play, reset videos to play list
        if (this.videosToPlay.length === 0) {
          this.videosToPlay = this.videoFiles;
        }

        // Refresh video display with load
        this.$refs.video.load();
        // Start video
        this.$refs.video.play();
      });
    },
  },
};
