import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import AboutContactForm from './about-contact-form';

export default {
  name: 'about',

  components: {
    AboutContactForm,
  },

  data: () => ({}),

  mounted() {
    this.animate();
  },

  methods: {
    animate() {
      this.animateHeader();
      this.animateHeroText();
      this.animateLinks();
      this.animateExpertise();
      this.animateServices();
      this.animateExperience();
      this.animateMethodology();
      this.animateClients();
      this.animateContact();
    },

    animateHeader() {
      this.animateTitle();
      this.animateScrollHint();
    },

    animateTitle() {
      const titles = gsap.utils.toArray('h1, h2');

      gsap.from(titles, {
        duration: 1,
        opacity: 0,
        y: 50,
        stagger: 0.2,
      });
    },

    animateScrollHint() {
      ScrollTrigger.create({
        trigger: '.page-header__scroll-hint',
        start: 'bottom bottom',
        end: '+=100',
        animation: gsap.to('.page-header__scroll-hint', {
          opacity: 0,
        }),
        toggleActions: 'play none none reverse',
      });
    },

    animateHeroText() {
      const heroIntro = gsap.to('.about__hero-intro', {
        backgroundImage:
          'linear-gradient(15deg, var(--color-bg) 100%, var(--color-intermediary) 150%, var(--color-primary-light) 200%, var(--color-bg) 300%)',
      });

      ScrollTrigger.create({
        trigger: '.about__hero-intro-wrapper',
        start: 'top top',
        end: '+=340%',
        // markers: true,
        pin: true,
        pinSpacing: true,
        scrub: 0.8,
        animation: heroIntro,
      });
    },

    animateLinks() {
      const links = gsap.utils.toArray('.about__link');

      links.forEach((link, index) => {
        const linkAnimation = gsap.from(link, {
          opacity: 0,
          x: -10,
        });

        ScrollTrigger.create({
          trigger: link,
          start: 'top 80%',
          // end: 'bottom -1000',
          // markers: true,
          animation: linkAnimation,
          toggleActions: 'play none none reverse',
        });
      });
    },

    animateExpertise() {
      ScrollTrigger.create({
        trigger: '.about__expertise',
        start: 'top 90%',
        end: '+=275%',
        scrub: 0.1,
        // markers: true,
        animation: gsap.to('.about__expertise-text', {
          transform: 'translateY(120vh)',
        }),
        toggleActions: 'play none none reverse',
      });
    },

    animateServices() {
      this.skewServices();
      this.animateServicesTitles();
      this.animateServicesFlares();
      this.animateServicesContent();
      this.animateServicesText();
      this.animateServicesCta();
    },

    skewServices() {
      // Skew animation

      const proxy = { skew: 0 };
      const skewSetter = gsap.quickSetter(
        '.about-service__title',
        'skewY',
        'deg'
      ); // fast
      const clamp = gsap.utils.clamp(-1, 1); // don't let the skew go beyond 20 degrees.

      ScrollTrigger.create({
        onUpdate: (self) => {
          const skew = clamp(self.getVelocity() / -300);
          // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            proxy.skew = skew;
            gsap.to(proxy, {
              skew: 0,
              duration: 0.8,
              ease: 'power3',
              overwrite: true,
              onUpdate: () => skewSetter(proxy.skew),
            });
          }
        },
      });

      // make the right edge "stick" to the scroll bar. force3D: true improves performance
      gsap.set('.about-service__title', {
        transformOrigin: 'right center',
        force3D: true,
      });
    },

    animateServicesTitles() {
      const triggers = gsap.utils.toArray('.about-service__title');

      triggers.forEach((trigger, index) => {
        const titleAnimation = gsap.from(trigger, {
          y: -10,
        });

        ScrollTrigger.create({
          trigger,
          start: 'top 40%',
          end: '+=300',
          // markers: true,
          scrub: 1,
          animation: titleAnimation,
        });
      });
    },

    animateServicesFlares() {
      const triggers = gsap.utils.toArray('.about-service');

      triggers.forEach((trigger, index) => {
        const flaresAnimation = gsap.from(
          `#${trigger.id} .about-service__header-bg-flare`,
          {
            opacity: 0,
            // add parallax effect using scrollTrigger's scroller proxy object
            y: (i) => {
              return 20 + i * 50;
            },
            x: -10,
            scale: 1.2,
            stagger: {
              each: 0.1,
            },
          }
        );

        ScrollTrigger.create({
          trigger,
          start: 'top 50%',
          end: '+=300',
          // markers: true,
          animation: flaresAnimation,
          scrub: 4,
          toggleActions: 'play none none reverse',
        });
      });
    },

    animateServicesContent() {
      // Section heading animation

      const headings = gsap.utils.toArray('.heading-about-section');

      headings.forEach((element, index) => {
        gsap.to(element, {
          backgroundImage:
            'linear-gradient(45deg, var(--color-bg) -100%, var(--color) 0%, var(--color) 100%, var(--color-bg) 150%)',
          duration: 2,
          ease: 'none',
          scrollTrigger: {
            trigger: element,
            start: 'top 80%',
            end: '+=175px',
            scrub: 1,
          },
        });
      });
    },

    animateServicesText() {
      const triggers = gsap.utils.toArray('.about-service__description');

      triggers.forEach((trigger, index) => {
        // section texts animations
        const paragraphsAnimation = gsap.from(`#${trigger.id} p`, {
          opacity: 0,
          x: -10,
          stagger: {
            each: 0.1,
          },
        });

        ScrollTrigger.create({
          trigger,
          start: 'top 70%',
          end: '+=250px',
          animation: paragraphsAnimation,
          scrub: 1,
        });
      });
    },

    animateServicesCta() {
      const triggers = gsap.utils.toArray(
        '.about-service__cta, .about-service__link'
      );

      triggers.forEach((trigger, index) => {
        const ctaAnimation = gsap.from(trigger, {
          opacity: 0,
          x: -10,
        });

        ScrollTrigger.create({
          trigger,
          start: 'top 70%',
          end: '+=250px',
          animation: ctaAnimation,
          scrub: 1,
        });
      });
    },

    animateExperience() {
      const animation = gsap.to('.about__hero-experience span', {
        backgroundImage:
          'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%,  rgba(0, 0, 0, 1) 120%, rgba(0, 0, 0, 1) 200%)',
      });

      ScrollTrigger.create({
        trigger: '.about__hero-experience',
        start: 'top center',
        end: 'bottom 90%',
        // markers: true,
        animation,
        scrub: 2,
      });

      const textAnimation = gsap.from('.about__hero-experience p', {
        opacity: 0,
        color: 'rgb(30, 225, 225)',
        x: -50,
      });

      ScrollTrigger.create({
        trigger: '.about__hero-experience',
        start: 'top center',
        end: '+=200',
        // markers: true,
        animation: textAnimation,
        scrub: 1,
      });
    },

    animateMethodology() {
      const textAnimation = gsap.from(`.about__methodology  p`, {
        opacity: 0,
        y: 50,
        stagger: {
          each: 0.1,
        },
      });

      ScrollTrigger.create({
        trigger: '.about__methodology',
        start: 'top 80%',
        // end: 'bottom -1000',
        // markers: true,
        animation: textAnimation,
        toggleActions: 'play none none reverse',
      });

      const stepsAnimation = gsap.from(`.about__methodology-step`, {
        opacity: 0,
        y: 50,
        stagger: {
          each: 0.15,
        },
        ease: 'back',
      });

      ScrollTrigger.create({
        trigger: '.about__methodology-steps',
        start: 'top 60%',
        // end: 'bottom -1000',
        // markers: true,
        scub: 0.2,
        animation: stepsAnimation,
        toggleActions: 'play none none reverse',
      });
    },

    animateClients() {
      const textAnimation = gsap.from(`.about__clients p`, {
        opacity: 0,
        y: 50,
        stagger: {
          each: 0.07,
        },
      });

      ScrollTrigger.create({
        trigger: '.about__clients-text',
        start: 'top 80%',
        end: '+=600',
        // markers: true,
        animation: textAnimation,
        scrub: 0.2,
        toggleActions: 'play none none reverse',
      });

      const logosAnimation = gsap.from(`.about__clients-logos img`, {
        opacity: 0,
        y: 50,
        stagger: {
          each: 0.01,
        },
        ease: 'back',
      });

      ScrollTrigger.create({
        trigger: '.about__clients-logos',
        start: 'top bottom',
        // end: 'bottom -1000',
        // markers: true,
        animation: logosAnimation,
        scrub: 0.1,
        toggleActions: 'play reverse reverse reverse',
      });
    },

    animateContact() {
      const headingAnimation = gsap.from('.about__contact-headings', {
        opacity: 0,
        y: 50,
      });

      ScrollTrigger.create({
        trigger: '.about__contact-headings',
        start: 'top 70%',
        // markers: true,
        animation: headingAnimation,
        scrub: 1,
        toggleActions: 'play none none reverse',
      });

      const formAnimation = gsap.from(
        '.about__contact-form div, .about__contact-form legend',
        {
          opacity: 0,
          y: 50,
          stagger: {
            each: 0.05,
          },
        }
      );

      ScrollTrigger.create({
        trigger: '.about__contact-form',
        start: 'top 60%',
        // end: 'bottom -1000',
        // markers: true,
        animation: formAnimation,
        toggleActions: 'play none none reverse',
      });
    },
  },
};
