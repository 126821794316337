import VueRecaptcha from 'vue-recaptcha';
import isEmail from '../utils/isEmail';

export default {
  name: 'contact-form',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      validationStarted: false,
      formIsSending: false,
      formSent: false,
      backendError: '',
      recaptchaResponse: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    hasValidName() {
      return this.name.length > 4;
    },
    hasValidPhone() {
      return this.phone.length > 9;
    },
    hasValidMessage() {
      return this.message.length > 4;
    },
    hasValidEmail() {
      return isEmail(this.email);
    },
    formIsValid() {
      return (
        this.hasValidName &&
        this.hasValidPhone &&
        this.hasValidMessage &&
        this.hasValidEmail &&
        this.recaptchaResponse
      );
    },
  },
  methods: {
    onVerify: function (response) {
      this.recaptchaResponse = response;
    },
    checkForm(e) {
      this.formIsSending = true;

      if (!this.validationStarted) this.validationStarted = true;

      if (!this.formIsValid) return (this.formIsSending = false);

      const { name, phone, message, email, recaptchaResponse } = this;

      fetch('/contact', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          name,
          phone,
          message,
          email,
          recaptchaResponse,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.formSent = true;
          this.formIsSending = false;
          window.location.href = data.redirect;
        })
        .catch((error) => {
          this.backendError = error;
          this.formIsSending = false;
        });
    },
  },
};
