import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

export default {
  name: 'images-gallery',
  data() {
    return {
      swiper: null,
      isActive: false,
      sliderIsInitialized: false,
    };
  },
  methods: {
    openGallery(slideIndex) {
      document.querySelector('html').classList.add('overflow-y-hidden');
      this.$refs.gallery.focus();

      if (!this.swiper) {
        this.swiper = new Swiper('.swiper-container', {
          observer: true,
          observeParents: true,
          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          // @TODO: Make lazy loading actually work!
          lazy: true,
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }

      this.isActive = true;
      this.sliderIsInitialized = true;

      this.$nextTick(() => {
        // if (!this.swiper) this.swiper = this.$refs.mySwiper.$swiper;
        this.swiper.update();
        this.swiper.slideTo(slideIndex, 0, false);
      });
    },
    onPrev() {
      this.swiper.slidePrev();
    },
    onNext() {
      this.swiper.slideNext();
    },
    onClose() {
      document.querySelector('html').classList.remove('overflow-y-hidden');
      this.isActive = false;
    },
  },
};
